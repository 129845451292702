// extracted by mini-css-extract-plugin
export var about_team = "about-team-module--about_team--c0d9f";
export var about_team_animation_fade_in_down = "about-team-module--about_team_animation_fade_in_down--b240a";
export var about_team_animation_fade_out_down = "about-team-module--about_team_animation_fade_out_down--5eff9";
export var about_team_card = "about-team-module--about_team_card--25468";
export var about_team_card_image = "about-team-module--about_team_card_image--6c989";
export var about_team_container = "about-team-module--about_team_container--a061c";
export var about_team_end = "about-team-module--about_team_end--5a718";
export var about_team_title = "about-team-module--about_team_title--ced6a";
export var fadeInDown = "about-team-module--fade-in-down--8b78a";
export var fadeOutDown = "about-team-module--fade-out-down--ff8b5";